import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { lenis } from '../lib/lenis';
import { topBar } from '../lib/topBar';
import { isInsideViewport } from '../lib/viewport';

function showList(list) {
  const items = [...list.querySelectorAll('.archive__link')];
  const inViewItems = items.filter((item) => isInsideViewport(item, -200));
  const outsideViewItems = items.filter((item) => !inViewItems.includes(item));

  gsap.fromTo('.archive__bg img', { scale: 0.5 }, { scale: 1 });

  list.classList.add('archive__list--is-animating');
  const tl = gsap.timeline({
    onComplete: () => list.classList.remove('archive__list--is-animating'),
  });
  let tt = 0;
  inViewItems.forEach((item) => {
    tl.fromTo(
      item,
      { opacity: 0 },
      { opacity: 1, duration: 0.7, ease: 'power2.in' },
      `${tt}`,
    );
    tl.fromTo(
      item,
      { yPercent: 50 },
      { yPercent: 0, duration: 0.4, ease: 'power4.out' },
      '<+=0.2',
    );
    tt += 0.1;
  });

  outsideViewItems.forEach((item) => {
    tl.fromTo(
      item,
      { opacity: 0 },
      { opacity: 1, duration: 0.7, ease: 'power2.in' },
      `${tt}`,
    );
    tl.fromTo(
      item,
      { yPercent: 50 },
      { yPercent: 0, duration: 0.4, ease: 'power4.out' },
      '<+=0.2',
    );
  });

  return tl;
}

function hideList(list) {
  const items = [...list.querySelectorAll('.archive__link')];
  const inViewItems = items.filter((item) => isInsideViewport(item, -200));
  const outsideViewItems = items.filter((item) => !inViewItems.includes(item));

  list.classList.add('archive__list--is-animating');
  const tl = gsap.timeline({
    // onComplete: () => list.classList.remove('archive__list--is-animating'),
  });

  tl.to('.archive__heading', { opacity: 0, duration: 0.7, ease: 'power2.in' });
  tl.addLabel('items', '<+=0.4');

  let tt = 0;
  inViewItems.forEach((item) => {
    tl.fromTo(
      item,
      { opacity: 1 },
      { opacity: 0, duration: 0.35, ease: 'power2.in' },
      `items+=${tt}`,
    );
    tl.fromTo(
      item,
      { yPercent: 0 },
      { yPercent: -30, duration: 0.2, ease: 'power4.out' },
      '<+=0.2',
    );
    tt += 0.05;
  });

  outsideViewItems.forEach((item) => {
    tl.fromTo(
      item,
      { opacity: 1 },
      { opacity: 0, duration: 0.35, ease: 'power2.in' },
      `${tt}`,
    );
    tl.fromTo(
      item,
      { yPercent: 0 },
      { yPercent: -30, duration: 0.2, ease: 'power4.out' },
      '<+=0.2',
    );
  });

  return tl;
}

export function archivePage() {
  const list = document.querySelector('.archive__list');
  const items = list.querySelectorAll('.archive__item');
  const links = [
    ...document.querySelectorAll('.archive__link'),
    ...document.querySelectorAll('.controls__button'),
  ];

  ScrollTrigger.create({
    trigger: 'main',
    start: 'top+=50 top',
    // markers: true,
    onToggle: (self) => {
      if (self.isActive) topBar.addBackground();
      else topBar.removeBackground();
    },
  });

  const tl = gsap.timeline();
  tl.fromTo(
    '.topbar',
    {
      yPercent: -100,
    },
    {
      yPercent: 0,
      y: 0,
      duration: 1,
      ease: 'power2.out',
    },
  );
  tl.fromTo(
    '.archive__heading',
    { opacity: 0 },
    { opacity: 1, duration: 0.7, ease: 'power2.in' },
    '<',
  );
  tl.fromTo(
    '.archive__heading',
    { yPercent: 50 },
    { yPercent: 0, duration: 0.4, ease: 'power4.out' },
    '<+=0.2',
  );

  tl.add(showList(list), '<');
  tl.fromTo(
    '.controls',
    { xPercent: -50, yPercent: 50, opacity: 0 },
    {
      xPercent: -50,
      yPercent: 0,
      opacity: 1,
      duration: 0.7,
      ease: 'power4.out',
    },
    '<25%',
  );

  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const href = e.currentTarget.href;

      hideList(list).then(() => {
        window.location = href;
      });
    });
  });

  items.forEach((item) => {
    // preload bg
    ScrollTrigger.create({
      trigger: item,
      start: 'top bottom', // preload next five images
      // markers: true,
      onEnter: () => {
        const img = item.querySelector('.archive__bg img');
        if (!img) return;

        img.setAttribute('loading', 'eager');
        img.hidden = false;
      },
    });
  });

  gsap.matchMedia().add('screen and (max-width: 1023px)', () => {
    items.forEach((item) => {
      // set active item
      ScrollTrigger.create({
        trigger: item,
        start: 'top center-=25px',
        end: 'bottom center-=25px',
        // markers: true,
        onEnter: () => {
          items.forEach((item) => {
            item.classList.remove('archive__item--is-active');
          });
          item.classList.add('archive__item--is-active');
        },
        onEnterBack: () => {
          items.forEach((item) => {
            item.classList.remove('archive__item--is-active');
          });
          item.classList.add('archive__item--is-active');
        },
      });
    });
  });

  // hide bg image when scrolling fast
  lenis.on('scroll', () => {
    if (Math.abs(lenis.velocity) > 0.5) {
      list.classList.add('archive__list--is-fast-scrolling');
    } else {
      list.classList.remove('archive__list--is-fast-scrolling');
    }
  });
}
